<ng-container>
  <div *ngIf="!isSubmitted">
    <div class="header-form">
      <h4 class="title">Atur Ulang Password</h4>
      <p class="subtitle">
        Masukkan email yang Anda gunakan ketika mendaftar di KedaiVocer. Kami akan kirimkan link untuk mengatur ulang password Anda.
      </p>
    </div>

    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <input
          type="email"
          class="form-control"
          placeholder="Masukkan email Anda"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
          (ngModelChange)="errors.email = formErrorMessage.email"
        >
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors">{{errors.email}}</div>
        </div>
      </div>

      <div class="full-btn-wrapper mt-4 mb-3">
        <button type="submit" class="btn btn-main btn-full">
          <span *ngIf="!loading">Kirim</span>
          <div class="spinner-border" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>

      <p class="text-center">
        Kembali ke <a [routerLink]="['/auth/login']" class="default-link">Masuk</a> atau <a [routerLink]="['/auth/register']" class="default-link">Daftar</a>
      </p>
    </form>
  </div>

  <div *ngIf="isSubmitted" class="success-wrapper">
    <img class="icon" src="../../../../../assets/images/icon/check.png" alt="check icon">

    <h3 class="title">
      Terima Kasih
    </h3>
    <p class="desc">
      Email sudah dikirim ke {{this.form.value['email']}}.
      Mohon cek inbox Anda secara berkala.
    </p>
  </div>
</ng-container>
